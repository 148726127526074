// import { Link } from "gatsby";
import React, { useState, useEffect } from "react"
import { Link } from "@StarberryUtils"
import {
  Button,
  Form,
  Container,
  FormControl,
  Row,
  InputGroup,
  Col,
} from "react-bootstrap"
import "./Header.scss"
import Logo from "../../images/locationl-logo.svg"
import MenuData from "./MenuData"
import { useStaticQuery, graphql } from "gatsby"
import $ from "jquery"
import { navigate } from "@reach/router"
import Helmet from "react-helmet"
import SearchInputBox from "../Search/predictive-search/search-inputbox"
import { isSafari, isIOS, isMacOs } from "react-device-detect"
const Header = props => {
  useEffect(() => {
    //console.log('aaaaaaaaaaaaaa',isSafari,isIOS)
    if (isSafari === true || isIOS === true || isMacOs === true) {
      $("body").addClass("iosdevicesonly")
    }
    $(".menubar-desktop .submenu").each(function (i, el) {
      // Remove white space
      var html = $(el).html().replace(/\s+/, "")
      // Check if element is :empty or length of html is zero
      if ($(el).is(":empty") || html.length == 0) {
        $(el).remove()
      } else {
        $(el).parent().addClass("has-submenu")
      }
    })
    if(typeof window !== "undefined"){
      // Google tag (gtag.js)
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.setAttribute('async','');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-1001626836';
      document.getElementsByTagName('head')[0].appendChild(script);

      const script2 = document.createElement('script');
      script2.innerHTML = " window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-1001626836'); ";
      document.getElementsByTagName('head')[0].appendChild(script2);
    }
  })

  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        servicesSubLink(publicationState: LIVE) {
          Main_menu {
            Main_Label
            Main_Url {
              id
              Label
              Primary_URL
            }
            HashTag {
              Hash_Url
              Label
              Sub_Url {
                id
                Label
                Primary_URL
              }
            }
          }
        }
        allMenus(sort: "Sorting:asc") {
          id
          Link_Type
          Label
          Primary_URL
          Secondary_URL
          Topmenu
          Template
          Main_Parent {
            id
            Label
            Primary_URL
            Secondary_URL
          }
          Sub_Parent {
            id
            Label
            Primary_URL
            Secondary_URL
          }
        }
      }
    }
  `)

  const [open, setOpen] = useState(false)
  const [state, setState] = useState([
    {
      name: "Marketing",

      isActive: false,
    },
    {
      name: "Search",
      // submenu: [
      //   {
      //     name: "Buy",
      //     isActive: false,
      //   },
      //   {
      //     name: "Sell",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "Rent",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "Landlords",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "New Homes",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      // ],
      isActive: false,
    },
    {
      name: "Services",
      submenu: [
        {
          name: "Buy",
          submenu: [
            "Buying Guide",
            "Property Calculator",
            "Register for Property Alertes",
          ],
          isActive: false,
        },
        {
          name: "Sell",
          submenu: [
            "Why sell with LL?",
            "Selling Guide",
            "Property Valuation",
            "Sales Progression",
            "Property Launch",
            "Black Book",
          ],

          isActive: false,
        },
        {
          name: "Rent",
          submenu: [
            "Renting with LL",
            "Renting Guide",
            "Tenant Charges",
            "Register for Property Alerts",
          ],

          isActive: false,
        },
        {
          name: "Landlords",
          submenu: [
            "Letting with LL",
            "Selling Guide",
            "Lettings Advice/Guide",
            "Landlord Fees",
            "Property Management",
          ],

          isActive: false,
        },
        {
          name: "New Homes",
          submenu: ["Land & New Homes", "Case Studies"],

          isActive: false,
        },
      ],
      isActive: false,
    },
    {
      name: "Why?",
      // submenu: [
      //   {
      //     name: "Buy",
      //     isActive: false,
      //   },
      //   {
      //     name: "Sell",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "Rent",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "Landlords",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "New Homes",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      // ],
      isActive: false,
    },
    {
      name: "People",
      // submenu: [
      //   {
      //     name: "Buy",
      //     isActive: false,
      //   },
      //   {
      //     name: "Sell",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "Rent",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "Landlords",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "New Homes",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      // ],
      isActive: false,
    },
    {
      name: "Contact",
      // submenu: [
      //   {
      //     name: "Buy",
      //     isActive: false,
      //   },
      //   {
      //     name: "Sell",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "Rent",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "Landlords",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "New Homes",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      // ],
      isActive: false,
    },
  ])

  const openMenu = () => {
    setOpen(!open)
  }

  const closeMenu = () => {
    setOpen(false)
  }

  const handlerOpenMenu = index => {
    let newState = [...state]
    let a = newState.map((item, i) => {
      if (index !== i) {
        return { ...item, isActive: false }
      } else {
        return item
      }
    })
    let isActive = a[index].isActive
    a[index].isActive = !isActive
    setState(a)
  }

  const handlerOpenSubMenu = (e, index, i) => {
    e.stopPropagation()
    let newState = [...state]
    let a = newState[index].submenu.map((item, j) => {
      if (i !== j) {
        return { ...item, isActive: false }
      } else {
        return item
      }
    })
    newState[index].submenu = a
    let isActive = newState[index].submenu[i].isActive
    newState[index].submenu[i].isActive = !isActive
    setState(newState)
  }
  var menu_data = data.glstrapi.allMenus
  var services_menu_data = data.glstrapi.servicesSubLink
  // console.log(menu_data, "menuuu")
  // search results page navigation
  useEffect(() => {
    let url = "/"

    $(".sales_btn").click(function () {
      $("html, body").scrollTop(0)
      var searcValue = $(".property-search_overlay .react-autosuggest__input")
        .val()
        .split(", ")
        .join("-")
        .replace(/ /g, "-")
        .replace(/'/g, "")
        .toLowerCase()
      if (searcValue !== "") {
        url = "in-" + searcValue + "/"
      } else {
        url = "in-stoke-newington-and-hackney-and-north-london-and-east-london/"
      }
      navigate("/property/for-sale/" + url)
    })

    $(".rent_btn").click(function () {
      $("html, body").scrollTop(0)
      var searcValue = $(".property-search_overlay .react-autosuggest__input")
        .val()
        .split(", ")
        .join("-")
        .replace(/ /g, "-")
        .replace(/'/g, "")
        .toLowerCase()
      if (searcValue !== "") {
        url = "in-" + searcValue + "/"
      } else {
        url = "in-stoke-newington-and-hackney-and-north-london-and-east-london/"
      }
      navigate("/property/to-rent/" + url)
    })
  }, [])
  //console.log(menu_data, "menu data")
  return (
    <React.Fragment>
      <header className={open ? "open-navigation header-fix" : "header-fix"}>
        <Helmet>
          {/* <script
            type="text/javascript"
            src="https://www.bugherd.com/sidebarv2.js?apikey=cxmt55he6rxj7dxgfysnnw"
            async="true"
          ></script> */}
          <meta
            name="viewport"
            content="width=device-width; initial-scale=1.0, maximum-scale=1.0, user-scalabale=0"
          />
        </Helmet>
        <Container className="header-padding-mob container-header">
          <div className="menudesktop">
            <div className="logo">
              <div className="logo-mobile">
                <Link to="/">
                  <img alt="Logo" src={Logo} />
                </Link>
              </div>
              <div className="logo-tab">
                <Link to="/">
                  <img alt="Logo" src={Logo} />
                </Link>
              </div>
              <div className="logo-desktop">
                <Link to="/">
                  <img alt="Logo" src={Logo} />
                </Link>
              </div>
            </div>
            <div className="menu-icon">
              {/* <button type="button" onClick={openMenu}>
                <i className="hamberger-black"></i>
              </button> */}
              <div onClick={openMenu} id="menuToggle">
                <input type="checkbox" />
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="menu-bar ">
              {/* <div className="btn-close-menu">
                <button type="button" onClick={closeMenu} className="close-btn">
                  <i className="icon-close"></i>
                </button>
              </div> */}
              <div className="menu-nav">
                <ul className="main-menu-list">
                  {menu_data.map((item, i) => (
                    <MenuData
                      key={i}
                      index={i}
                      handlerOpenMenu={handlerOpenMenu}
                      handlerOpenSubMenu={handlerOpenSubMenu}
                      item={item}
                      services_menu_data={services_menu_data}
                      openMenu={openMenu}
                      closeMenu={closeMenu}
                      setOpen={setOpen}
                    />
                  ))}
                </ul>
              </div>
            </div>
            <div className="menubar-desktop">
              <ul className="mainSubmenu">
                {menu_data.map(menuitem => {
                  return (
                    <>
                      {menuitem.Topmenu === true &&
                        menuitem.Label != "Search" &&
                        menuitem.Label != "Services" && (
                          <>
                            <li>
                              {menuitem.Secondary_URL ? (
                                <Link to={menuitem.Secondary_URL}>
                                  {menuitem.Label}
                                </Link>
                              ) : (
                                <Link to={`/${menuitem.Primary_URL}`}>
                                  {menuitem.Label}
                                </Link>
                              )}
                              <ul className="submenu">
                                {menu_data.map(submenu => {
                                  return (
                                    <>
                                      {submenu.Main_Parent &&
                                        submenu.Main_Parent.Label ==
                                          menuitem.Label &&
                                        submenu.Sub_Parent == null && (
                                          <>
                                            {submenu.Secondary_URL ? (
                                              <li>
                                                <Link
                                                  to={submenu.Secondary_URL}
                                                >
                                                  {submenu.Label}
                                                </Link>
                                              </li>
                                            ) : (
                                              <li>
                                                <Link
                                                  to={`/${menuitem.Primary_URL}/${submenu.Primary_URL}`}
                                                >
                                                  {submenu.Label}
                                                </Link>
                                              </li>
                                            )}
                                          </>
                                        )}
                                    </>
                                  )
                                })}
                              </ul>
                            </li>
                          </>
                        )}
                      {menuitem.Topmenu === true &&
                        menuitem.Label === "Search" && (
                          <li className="megamenu-tab">
                            <a href="javascript:;">Search</a>
                            <div className="megamenu-main">
                              <div className="megamenu">
                                <div className="megamenu-inner">
                                  <Row>
                                    <Col lg={12}>
                                      <h3 className="megamenu-head">
                                        Let’s find your new home together
                                      </h3>
                                      <p className="megamenu-content">
                                        Find your dream home with us, we have a
                                        wide range of property that suits your
                                        need.
                                      </p>
                                      <div className="form-inline header-forms property-search_overlay">
                                        <SearchInputBox />
                                        {/* <InputGroup className="mb-2 mr-sm-2 form-border-group">
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className="icon-search"></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            id="inlineFormInputGroupUsername2"
                            type="search"
                            placeholder="Street, area or postcode"
                            className="search_field_text"
                          />
                        </InputGroup> */}
                                        <div className="btn-box">
                                          <Button
                                            className="sales_btn"
                                            variant="primary"
                                          >
                                            For Sale
                                          </Button>
                                          <Button
                                            className="rent_btn"
                                            variant="primary btn-right"
                                          >
                                            To Rent
                                          </Button>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </li>
                        )}
                                              {menuitem.Topmenu === true &&
                        menuitem.Label === "Services" && (
                          <>
                            <li>
                              <Link
                            // to={`/${menuitem.Primary_URL}`}
                            >
                              {menuitem.Label}
                            </Link>
                              <ul className="submenu">
                                {menu_data?.map(submenu => {
                                  return (
                                    <>
                                      {submenu.Main_Parent &&
                                        submenu.Main_Parent.Label ==
                                          menuitem.Label &&
                                        submenu.Sub_Parent == null && (
                                          <>
                                            {submenu.Secondary_URL ? (
                                              <li>
                                                <Link
                                                  to={submenu.Secondary_URL}
                                                >
                                                  {submenu.Label}
                                                </Link>
                                              </li>
                                            ) : (
                                              <li>
                                                <Link
                                                  to={`/${menuitem.Primary_URL}/${submenu.Primary_URL}`}
                                                >
                                                  {submenu.Label}
                                                </Link>
                                              </li>
                                            )}
                                          </>
                                        )}
                                    </>
                                  )
                                })}
                              </ul>
                            </li>
                          </>
                        )}
                      {/* {menuitem.Topmenu === true &&
                        menuitem.Label === "Services" && (
                          <li className="megamenu-tab megamenu-large">
                            <Link
                            // to={`/${menuitem.Primary_URL}`}
                            >
                              {menuitem.Label}
                            </Link>
                            <div className="megamenu-main">
                              <div className="megamenu">
                                <div className="megamenu-inner">
                                  <Row>
                                    {services_menu_data?.Main_menu?.map(
                                      submenu1 => {
                                        return (
                                          <>
                                            {submenu1.Main_Url &&
                                              submenu1.Main_Label && (
                                                <Col
                                                  lg={4}
                                                  className="mg-bottom-menu-item"
                                                >
                                                  <span className="mega-menu-list-head">
                                                    <Link
                                                      to={`/${menuitem.Primary_URL}/${submenu1?.Main_Url?.Primary_URL}`}
                                                    >
                                                      {submenu1.Main_Label}
                                                    </Link>
                                                  </span>
                                                  <ul>
                                                    {submenu1?.HashTag?.map(
                                                      submenu2 => {
                                                        return (
                                                          <>
                                                            {submenu2?.Hash_Url &&
                                                              submenu2?.Label && (
                                                                <li>
                                                                  <a
                                                                    href={`/${menuitem.Primary_URL}/${submenu1?.Main_Url?.Primary_URL}#section-${submenu2.Hash_Url}`}
                                                                  >
                                                                    {
                                                                      submenu2.Label
                                                                    }
                                                                  </a>
                                                                </li>
                                                              )}
                                                            {submenu2?.Label ===
                                                              "Area Guides" && (
                                                              <li>
                                                                <a
                                                                  href={`/${submenu2.Sub_Url.Primary_URL}`}
                                                                >
                                                                  {
                                                                    submenu2?.Label
                                                                  }
                                                                </a>
                                                              </li>
                                                            )}
                                                          </>
                                                        )
                                                      }
                                                    )}
                                                  </ul>
                                                </Col>
                                              )}
                                          </>
                                        )
                                      }
                                    )}
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </li>
                        )} */}
                    </>
                  )
                })}
              </ul>
            </div>
          </div>
        </Container>
      </header>
    </React.Fragment>
  )
}

export default Header
